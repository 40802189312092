<template>
  <div class="registration">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic" />

    <!-- <RegistrationStep :step="3" /> -->

    <section id="registration_message">
      <div class="container registration__container w1000">
        <h5 class="txt-bold txt-center h4">
          {{ $t('registrationSuccess') }}
        </h5>

        <ul class="message_info">
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('yourName') }} |</span>
              <span>{{ comoleteDataText.name }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span v-if="isTaiwanId" class="t txt-dark_gray">{{ $t('uid') }} |</span>
              <span v-else class="t txt-dark_gray">{{ $t('passportOrEntryPassNum') }} |</span>
              <span>{{ comoleteDataText.account }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('registrationCategory') }} |</span>
              <span>{{ comoleteDataText.devision }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('registrationDate') }} |</span>
              <span>{{ comoleteDataText.date }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('registrationTime') }} |</span>
              <span :class="{ 'doctor--red': doctorWang || doctorHong }">{{ comoleteDataText.time }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('registrationBranch') }} |</span>
              <span>{{ $t(comoleteDataText.branch) }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('address') }} |</span>
              <span>{{ $t(comoleteDataText.address) }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('doctor') }} |</span>
              <span>{{ translateDoctor }} {{ $t('doctor') }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('room') }} |</span>
              <!-- <span>{{comoleteDataText.place}}診</span> -->
              <span>{{ $t(comoleteDataText.clinic) }} </span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t('number') }} |</span>
              <span>{{ comoleteDataText.num }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-dark_gray">{{ $t("remark") }} |</span>
              <span>{{ comoleteDataText.remark }} </span>
            </p>
          </li>
        </ul>
      </div>
      <p class="w1000 small txt-theme txt-center small_note">．{{ $t('comingInfo') }}</p>
    </section>

    <div class="w1000 registration__item__container">
      <DoctorNotice class="registration__item__main" />

      <div class="doctor-ads-wrap registration__item__main">
        <DoctorAds />
      </div>
    </div>

    <section id="registration_btnbox">
      <div class="w1000 box">
        <router-link :to="{ name: 'HomeWithLang' }" class="btn btn-dark p">
          {{ $t('backToIndex') }}
        </router-link>
        <button type="button" class="btn p" @click="toMemberHome()">
          {{ $t('myregistration') }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
// import RegistrationStep from '@/components/registration/RegistrationStep.vue';

import { mapState, mapMutations } from 'vuex';
import { devisionZH, timeZH } from '@/utils/const';

import { isNationalIdentificationNumberValid } from 'taiwan-id-validator';
import isDoctorWang from '@/mixins/isDoctorWang';
import isDoctorHong from '@/mixins/isDoctorHong';
import isDoctorChen from '@/mixins/isDoctorChen';

import pic01 from '@/assets/img/index/imglink_1.jpg';
import { getApiLangCode } from '@/utils/getLangCode';
import DoctorAds from '@/components/registration/DoctorAds.vue';
import DoctorNotice from '@/components/registration/DoctorNotice.vue';
import { formatCustom } from '@/utils/getWeeks';

export default {
  name: 'RegistrationComplete',
  components: {
    BannerKv,
    DoctorAds,
    DoctorNotice,
    // RegistrationStep
  },
  mixins: [isDoctorWang, isDoctorHong, isDoctorChen],
  data() {
    return {
      // isTaiwanId: false,
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01
      }
      // comoleteDataText: {
      //   date: null,
      //   time: null,
      //   devision: null,
      //   doctor: null,
      //   place: null,
      //   num: null,
      //   name: null,
      //   account: null,
      // },
    };
  },
  computed: {
    ...mapState(['appointmentData', 'member', 'appointmentComplete', 'doctorMapping']),

    // 奇怪的醫生，詳細看這張單 https://howdesign.atlassian.net/browse/NUWA-1495
    doctorWang() {
      return this.isDoctorWang(this.appointmentData);
    },

    doctorHong() {
      return this.isDoctorHong(this.appointmentData);
    },
    doctorChen() {
      return this.isDoctorChen(this.appointmentData);
    },
    comoleteDataText() {
      if (!this.appointmentData?.uuid) {
        return {};
      }

      let extraShfitTime = false;
      if (this.isDoctorWang(this.appointmentData)) {
        extraShfitTime = 101;
      }

      let remark = '';
      if (!this.appointmentData?.remark) {
        remark = '';
      } else {
        const langCode = getApiLangCode();
        remark = this.appointmentData.remark[langCode];
      }

      if (this.isDoctorHong(this.appointmentData)) {
        extraShfitTime = 102;
      }

      if (this.isDoctorChen(this.appointmentData)) {
        extraShfitTime = 103;
      }

      return {
        date: `${this.appointmentData.date} (${formatCustom(this.appointmentData.date, 'dd')})`,
        time: timeZH(this.appointmentData.shift_code, extraShfitTime),
        devision: devisionZH(this.appointmentData.devision),
        doctor: this.appointmentData.name,
        branch: this.appointmentData?.branch,
        address: this.appointmentData?.address,
        clinic: this.appointmentData.clinic,
        num: this.appointmentComplete || '',
        name: this.member?.name,
        account: this.member?.account,
        doctorId: this.appointmentData?.doctor_id,
        remark: remark || '-',
      };
    },
    translateDoctor() {
      if (this.comoleteDataText.doctorId) {
        return this.doctorMapping[String(this.comoleteDataText.doctorId)].label;
      }

      return '';
    },
    isTaiwanId() {
      return isNationalIdentificationNumberValid(this.member?.account);
    }
  },
  methods: {
    ...mapMutations(['clearAppointmentData', 'clearAppointmentComplete']),
    initComoleteDataText() {
      if (!this.appointmentComplete || !this.appointmentData?.uuid) {
        this.$customSWAL({
          icon: 'error',
          title: this.$t('dataError'),
          thenFunc: () => {
            // this.toMemberHome();
            this.$router.push({
              name: 'MemberHome'
            });
          }
        });
      }
    },
    toMemberHome() {
      this.clearAppointmentData();
      this.clearAppointmentComplete();
      this.$router.push({ name: 'MemberHome' });
    },
    showMessage() {
      const title = '患者<br>請依照下列時間報到';
      const html = `
      每週一至週四: 08:00-21:00<br>
      週五: 08:00-17:00<br>
      週六: 08:00-12:00<br>
      <br>
      看診時間：<br>
      早診為 08:30-12:00(請於11:00前報到)<br>
      午診為 14:00-17:00(請於16:00前報到)<br>
      晚診為 18:00-21:00(請於20:00前報到`;

      const day = new Date(this.appointmentData.date).getUTCDay();
      if (this.member.is_first === 'Y' && this.appointmentData.doctor_code === 'D01') {
        // 這裡的條件已經沒有意義，但不知道測試畫面在哪，剛好所有要改成同一個文案，所以直接變數統一處理
        // reference: https://howdesign.atlassian.net/browse/NUWACARE-62
        if (this.appointmentData.shift_code === '1' && day > 0 && day <= 5) {
          this.$customSWAL({
            icon: 'info',
            title,
            html
          });
        } else if (this.appointmentData.shift_code === '4' && day > 0 && day <= 4) {
          this.$customSWAL({
            icon: 'info',
            title,
            html
          });
        } else if (this.appointmentData.shift_code === '1' && day === 6) {
          this.$customSWAL({
            icon: 'info',
            title,
            html
          });
        }
      }
    }
  },
  mounted() {
    this.initComoleteDataText();
    this.showMessage();
  },
  updated() {
    // this.initComoleteDataText();
  },
  destroyed() {
    this.clearAppointmentData();
    this.clearAppointmentComplete();
  }
};
</script>

<style lang="scss">
.doctor {

  &--red {
    color: #ff2d2d;
  }
}

.doctor-ads-wrap {
  margin-top: -20px;

  &:empty {
    display: none;
  }
}
</style>
